.add-admin {
    display: flex;
    justify-content: start;
    text-align: start;
    padding: 20px 40px
}
.add-admin button {
    background: #E7F0FF;
    border: none;
    outline: none;
    border-radius: 20px;
    color: #006AFF;
    font-size: 18px;
    padding: 20px;
}
.modal-add {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(0);
    transition: 0.3s;
    background: rgba(168, 193, 236, 0.63);
}

.modal-add.open {
    transform: scale(1);
  }
  .modal-body.open .modal-body{
    transition: all 0.5s ease;
    transform: scale(1);
  }
.modal-add .modal-body {
    top: 30%;
    left: 40%;
    width: 400px;
    height: 400px;
    border-radius: 20px;
    background-color: #FFFF;
    box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2)
}
.modal-add .modal-body .content-modal {
    display: flex;
    justify-content: start;
    text-align: start;
    flex-direction: column;
}
.modal-add .modal-body .content-modal p {
    margin-bottom: 0;
    padding: 5px 10px;
    font-size: 15px;
    color: rgba(17, 17, 17, 0.3);
}
.modal-add .modal-body .content-modal input, .modal-add .modal-body .content-modal select {
    width: 100%;
    border-radius: 18px;
    border: none;
    outline: none;
    padding: 10px 20px;
    background-color:rgba(17, 17, 17, 0.03);;
}
.admin-block {
   position: relative;
}
.btn-div-modal {
    margin-top: 10px;
}
.btn-div-modal button {
    border: 3px solid rgba(17, 17, 17, 0.04);
    outline: none;
    border-radius: 18px;
    width: 180px;
    color: #006AFF; 
    height: 50px;
    background: transparent;
}
.btn-div-modal button:last-child {
    border: none;
    background-color: #E7F0FF;
}
.second-block-speaker table tbody tr th svg {
    width: 70px;
    height: 70px;
}
@media (max-width: 780px) {
    .modal-add .modal-body {
        left: 25%;
    }
    .add-admin button{
        padding: 10px;
        font-size: 15px;
    }
}
@media (max-width: 580px) {
    .modal-add .modal-body {
        left: 15%;
        top: 20%;
        width: 340px;
    }
    .btn-div-modal button {
        width: 140px;
        height: 45px;
    }
}