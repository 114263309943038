.user-id-page {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 100px;
}
.second-block-user-id,
.third-block-user-id,
.fourth-block-user-id ,
.fifth-block-user-id,
.six-block-user-id{
    border-bottom: 1px solid rgba(17, 17, 17, 0.2);
    width: 100%;
}
.second-block-user-id div:first-child {
    border-right: 1px solid rgba(17, 17, 17, 0.2);
}

.second-block-user-id p {
    padding-left: 40px;
    color: rgba(17, 17, 17, 0.36);
}
.second-block-user-id div {
    width: 100%;
    padding-left: 30px;
}
.second-block-user-id div input {
    background: rgba(17, 17, 17, 0.03);
    border-radius: 18px;
    width: 70%;
    padding-left: 20px;
    height: 50px;
    border: none;
    outline: none;
}
.second-block-user-id div button{
    border: none;
    outline: none;
    width: 20%;
    height: 50px;
    border-radius: 18px;
}
.second-block-user-id div .red-btn button {
    background: rgba(235, 87, 87, 0.12) !important;
    color: #EB5757 !important;

}
.second-block-user-id div:last-child button {
    background: #E7F0FF;
    color: #006AFF;
}
.third-block-user-id div{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 70px;
}
.third-block-user-id p {
    font-size: 20px;
    font-family: Gilroy-Medium, sans-serif;
    color: rgba(17, 17, 17, 0.36);
}
.third-block-user-id textarea{
    height: 200px;
    width: 97%;
    background: rgba(17, 17, 17, 0.03);
    border-radius: 18px;
    font-size: 20px;
    outline: none;
    resize: none;
    border: none;
}
.third-block-user-id .btn-user-block {
    display: flex;
    justify-content: end;
    text-align: end;
    align-items: end;
    width: 97%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.third-block-user-id .btn-user-block button{
    background: rgba(235, 87, 87, 0.12);
    color: #EB5757;
    border: none;
    outline: none;
    width: 150px;
    height: 50px;
    border-radius: 18px;
}
.fourth-block-user-id .body-fourth-block-user{
    width: 70%;
    height: 100px;
    padding-left: 30px;
}
.fourth-block-user-id .body-fourth-block-user div span {
    font-size: 22px;
    padding: 0;
    margin: 0;
}
.fourth-block-user-id .body-fourth-block-user div:nth-child(2){
    margin-left: 50px;
}
.fourth-block-user-id .body-fourth-block-user div p {
    font-family: Gilroy-Medium, sans-serif;
    font-size: 22px;
    padding-left: 20px;
    padding-top: 5px;
    color: #006aff;
    margin: 0;
}
.fifth-block-user-id .body-fifth-block-user-id {
    width: 90%;
    padding-left: 40px;
}
.fifth-block-user-id .body-fifth-block-user-id  div{
    width: 100%;
}
.fifth-block-user-id .body-fifth-block-user-id  div {
    font-size: 21px;
    font-family: Gilroy-Medium, sans-serif;
}
.six-block-user-id div {
    width: 100%;
    padding-left: 20px;
}
.six-block-user-id div input{
    width: 500px;
    height: 75px;
    background: rgba(17, 17, 17, 0.03);
    color: rgba(17, 17, 17, 0.8);
    border-radius: 18px;
    border: none;
    font-family: Gilroy-Medium, sans-serif;
    outline: none;
    padding-left: 20px;
    font-size: 22px;
}
.six-block-user-id div  p {
    padding-left: 10px;
    padding-top: 10px;
    font-size: 16px;
    color: rgba(17, 17, 17, 0.36);
}
@media (max-width: 1100px) {
    .six-block-user-id div input {
    width: 400px !important;
    font-size: 20px;
    }
    .second-block-user-id div .red-btn button {
        font-size: 18px;
    }
    .second-block-user-id div {
        padding-left: 15px;
    }
    .second-block-user-id div input {
        width: 65%;
        font-size: 16px;
    }
    .second-block-user-id p {
        padding-left: 25px;
    }
}
@media (max-width: 800px) {
    .six-block-user-id {
        flex-direction: column;
        margin-bottom: 30px;
    }
    .six-block-user-id div input {
        width: 95% !important;
        font-size: 20px;
    }
    .second-block-user-id {
        flex-direction: column;
    }
    .second-block-user-id div:first-child {
        border-right: none;
        border-top: 1px solid rgba(17, 17, 17, 0.2);
    }
    .third-block-user-id div {
        align-items: start;
        padding-left: 40px;
        padding-top: 10px;
    }
    .third-block-user-id .btn-user-block {
        width: 85%;
    }
    .third-block-user-id textarea {
        width: 87%;
    }
    .fourth-block-user-id .body-fourth-block-user {
        width: 100%;
    }
    .fifth-block-user-id .body-fifth-block-user-id {
        flex-direction: column;
    }
    .fifth-block-user-id .body-fifth-block-user-id .title-span {
        width: 50%;
    }
}
@media (max-width: 500px) {
    .fourth-block-user-id .body-fourth-block-user {
        flex-direction: column;
        width: 100%;
        padding-top: 15px;
    }
    .fourth-block-user-id .body-fourth-block-user div p {
        font-size: 18px;
    }
    .fourth-block-user-id .body-fourth-block-user div:nth-child(2),
    .fourth-block-user-id .body-fourth-block-user div:nth-child(1)  {
        margin-left: 10px;
        width: 100%;
    }

    .fourth-block-user-id .body-fourth-block-user.align-items-center {
     align-items: start !important;
    }
    .fourth-block-user-id .body-fourth-block-user div span {
        width: 60%;
        font-size: 18px;
    }
    .fifth-block-user-id .body-fifth-block-user-id .title-span {
        width: 70%;
        font-size: 18px;
    }
    .fifth-block-user-id .body-fifth-block-user-id div p {
        font-size: 18px;
    }
    .third-block-user-id p {
        font-size: 18px;
    }

    .second-block-user-id div:last-child button {
        font-size: 14px;
    }
}