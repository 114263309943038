body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
    list-style: none;
}

.wrapper {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
}

.content-container {
    width: 83%;
}
.overlay {
  transition: 0.4s;
  left: 0 !important;
  background: rgba(255, 255, 255, 0.2);
  overflow-y: scroll;
  height: 100%;
}
@media (max-width: 1210px) {
    .content-container {
      width: 100%;
    }

}
