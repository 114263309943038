@font-face {
  font-family: "Gilroy-Medium";
  src: url("../../fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "LibreBaskerville-Regular";
  src: url("../../fonts/LibreBaskerville-Regular.ttf");
}
@font-face {
  font-family: "LibreBaskerville-Italic";
  src: url("../../fonts/LibreBaskerville-Italic.ttf");
}

.speaker-block {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  align-items: center;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 100px;
  /*padding-left: 10px;*/
}
.second-block-speaker {
  overflow: auto;
}
.first-block-speaker {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.first-block-speaker div {
  width: 150px;
}
.first-block-speaker div span {
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  font-family: Gilroy-Medium, sans-serif;
}
.first-block-speaker div span.active {
  background: #e7f0ff;
  border-radius: 12px;
  color: #006aff;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.second-block-speaker table {
  width: 100%;
}
.second-block-speaker table thead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.second-block-speaker table thead tr {
  /*text-align: start;*/
}
.second-block-speaker table thead tr th {
  padding: 25px;
  font-family: Gilroy-Medium, sans-serif;
  color: rgba(0, 0, 0, 0.6);
}
.second-block-speaker table thead tr th:nth-child(1) {
  width: 30%;
  text-align: start;
  align-items: start;
}
.button-more {
  border: none;
  outline: none;
  background-color: #006aff;
  color: #ffff;
  padding: 15px;
  border-radius: 15px;
  margin-top: 10px;
}
.second-block-speaker table thead tr th:nth-child(2) {
  width: 15%;
}
.second-block-speaker table thead tr th:nth-child(3) {
  width: 15%;
}
.second-block-speaker table thead tr th:nth-child(4) {
  width: 20%;
}
.second-block-speaker table thead tr th:nth-child(5) {
  width: 20%;
}
.second-block-speaker table tbody {
  width: 100%;
}
.second-block-speaker table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.second-block-speaker table tbody tr th:first-child {
  cursor: pointer;
}
.second-block-speaker table tbody tr th {
  padding: 30px;
  font-family: Gilroy-Medium, sans-serif;
  font-size: 15px;
}
.first-th-with-logo img {
  width: 50px;
  height: 50px;
  border-radius: 15px;
}
.first-th-with-logo div p {
  font-family: Gilroy-Medium, sans-serif;
  font-size: 18px;
  text-align: start;
}
.first-th-with-logo div span {
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 1100px) {
  .second-block-speaker table thead tr {
    font-size: 15px;
  }
  .first-th-with-logo div p {
    font-size: 15px !important;
  }
  .first-th-with-logo div span {
    font-size: 15px !important;
  }
  .second-block-speaker table tbody tr th {
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .second-block-speaker table thead tr {
    font-size: 13px;
  }
  .first-th-with-logo div p {
    font-size: 13px !important;
  }
  .first-th-with-logo div span {
    font-size: 13px !important;
  }
  .second-block-speaker table tbody tr th {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .second-block-speaker table thead tr {
    font-size: 11px;
  }
  .first-th-with-logo img {
    width: 40px !important;
    height: 40px !important;
  }
  .first-th-with-logo div p {
    font-size: 11px !important;
  }
  .first-th-with-logo div span {
    font-size: 11px !important;
  }
  .second-block-speaker table tbody tr th {
    font-size: 10px;
  }
}
