.MuiAccordion-root {
  border: none !important;
  box-shadow: none !important;
  color: rgba(17, 17, 17, 0.5) !important;
  font-size: 18px !important;
}
.MuiButtonBase-root {
  padding: 0 !important;
  width: 174px !important;
  min-height: 50px !important;
}
.MuiTypography-root {
  font-size: 17px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.MuiAccordionSummary-content {
  margin: 0 !important;
}
.MuiAccordionDetails-root {
  padding: 0 !important;
}
.MuiSvgIcon-root {
  width: 22px !important;
  height: 22px !important;
}
.Mui-expanded {
  border-bottom: none !important;
  font-size: 18px !important;
}

.MuiAccordionDetails-root {
  border-top: none;
}
